import React from 'react'
import styled from 'styled-components'
import { Wrapper, Grid, H } from '@farewill/ui'
import { BREAKPOINT, COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import PreventOrphan from 'components/PreventOrphan'
import BreakpointSwitch from 'components/BreakpointSwitch'
import Texture from 'components/Texture'
import type { WrapperWithEdgeProps } from 'components/WrapperWithEdge'

import WillOptionsWrapper from './components/WillOptionsWrapper'
import WillOptionCard, { WillOptionCardProps } from './WillOptionCard'
import type { WillOption } from './types'

const CARD_MIN_WIDTH = 360
const CARD_MAX_WIDTH = 460
/**
 * The minimum screen width at which we can fit 3 cards with all spacing
 */
const GRID_BREAKPOINT =
  CARD_MIN_WIDTH * 3 + parseInt(GTR.M, 10) * (3 - 1) + parseInt(GTR.L, 10) * 2

export type WillOptionsProps = {
  edgeBackgroundColor?: string
  edgeDirection?: WrapperWithEdgeProps['direction'] | 'none'
  isFree?: boolean
  options: WillOption[]
  cardProps?: Omit<WillOptionCardProps, 'data'>
  footer?: React.ReactElement
}

const StyledFingerprintTexture = styled(Texture)`
  transform: rotate(145deg);
  top: -40px;
  right: -25%;

  ${screenMin.l`
    right: -15%;
  `}

  ${screenMin.xl`
    right: -5%;
  `}
`

const StyledBlobTexture = styled(Texture)`
  top: 425px;
  left: 0;

  @media (min-width: ${GRID_BREAKPOINT}px) {
    top: 195px;
  }
`

const StyledDotsTexture = styled(Texture)`
  transform: scaleX(-1);
  top: 445px;
  left: -2%;

  @media (min-width: ${GRID_BREAKPOINT}px) {
    top: 215px;
  }
`

const StyledContentWrapper = styled(Wrapper)`
  position: relative;
  z-index: 2;
`

const StyledCard = styled(WillOptionCard)`
  max-width: ${CARD_MIN_WIDTH}px;
  margin-right: auto;

  ${screenMin.m`
    max-width: ${CARD_MAX_WIDTH}px;
    margin-left: auto;
  `}
`

const WillOptions = ({
  cardProps,
  edgeDirection = 'down',
  edgeBackgroundColor = COLOR.BACKGROUND.FOG,
  isFree,
  options,
  footer,
}: WillOptionsProps): React.ReactElement => (
  <WillOptionsWrapper
    edgeBackgroundColor={edgeBackgroundColor}
    edgeDirection={edgeDirection}
  >
    <BreakpointSwitch
      breakAt={BREAKPOINT.S}
      belowComponent={null}
      aboveComponent={
        <>
          <StyledFingerprintTexture
            path="textures/fingerprint-yellow-03_rolgha"
            width={574}
            stretch
          />
          <StyledBlobTexture
            path="textures/blob-yellow-4"
            width={250}
            stretch
          />
          <StyledDotsTexture
            path="textures/yellow-detailed-dots"
            width={270}
            stretch
          />
        </>
      }
    />

    <StyledContentWrapper container noTrim>
      <H size="L" decorative centeredFromM>
        <PreventOrphan>Award winning wills, brilliant value</PreventOrphan>
      </H>

      <BreakpointSwitch
        breakAt={GRID_BREAKPOINT}
        belowComponent={
          <Wrapper margin={['XL', 0]}>
            {options.map((willOption) => (
              <Wrapper key={willOption.title} margin={[0, 0, 'M']}>
                <StyledCard
                  data={willOption}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...cardProps}
                  isFree={isFree}
                />
              </Wrapper>
            ))}
          </Wrapper>
        }
        aboveComponent={
          <Grid
            margin={['XL', 0, 0]}
            gap={[0, 'M']}
            gapFromL={[0, 'L']}
            gapFromXL={[0, 'XL']}
          >
            {options.map((willOption, index) => {
              const CARD_SPAN = 4

              return (
                <Wrapper
                  key={willOption.title}
                  as={WillOptionCard}
                  data={willOption}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...cardProps}
                  isFree={isFree}
                  // Note: We're only supporting cases where telephone wills is
                  // enabled for the experiment so telephoneWillsEnabled is
                  // hardcoded to true here.
                  telephoneWillsEnabled
                  gridItemProps={{
                    span: CARD_SPAN,
                    // When there are only 2 options, center align the two cards
                    ...(options.length === 2 && {
                      startColumn: index * CARD_SPAN + (CARD_SPAN - 1),
                    }),
                  }}
                />
              )
            })}
          </Grid>
        }
      />

      {footer}
    </StyledContentWrapper>
  </WillOptionsWrapper>
)

export default WillOptions
