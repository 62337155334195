import React from 'react'
import styled from 'styled-components'
import { Wrapper, H, P, Divider, Grid } from '@farewill/ui'
import { BORDER_RADIUS, COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { GridItemProps } from '@farewill/ui/components/Grid/types'

import { WillOption } from '../types'
import OptionalExtras from './components/OptionalExtras'

export type WillOptionCardProps = {
  isFree?: boolean
  gridItemProps?: GridItemProps
  className?: string
  alwaysShowOptionalExtras?: boolean
  data: WillOption
  telephoneWillsEnabled?: boolean
}

const CARD_BORDER_RADIUS = BORDER_RADIUS.XL
const PRICE_DISCOUNT_RED = '#ea0038'

const StyledWrapper = styled(Wrapper)`
  border-radius: ${CARD_BORDER_RADIUS};
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

const StyledTopWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const StyledContentWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const StyledHeader = styled(Wrapper)`
  border-radius: ${CARD_BORDER_RADIUS} ${CARD_BORDER_RADIUS} 0 0;
`

const StyledTitle = styled(H)`
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

const StyledFreePrice = styled.s`
  text-decoration: line-through 1.5px;
`

const StyledFree = styled.span`
  color: ${PRICE_DISCOUNT_RED};
`

const StyledBulletIconWrapper = styled.div`
  display: inline-block;
  height: ${GTR.M};
  left: 0;
  position: absolute;
  top: 0px;
  width: ${GTR.M};
`

const StyledBulletList = styled(Wrapper)`
  > li {
    padding-inline-start: ${GTR.L};
    position: relative;
  }

  > li:not(:last-child) {
    margin-bottom: ${GTR.XS};
  }
`

const WillOptionCard = ({
  data,
  isFree = false,
  className,
  gridItemProps,
  alwaysShowOptionalExtras = false,
  telephoneWillsEnabled = true,
}: WillOptionCardProps): React.ReactElement => {
  const {
    title,
    description,
    bullets,
    singlePrice,
    couplesPrice,
    footerText,
    optionalExtras,
    cta: Cta,
  } = data

  const COUPLES_SAVING = couplesPrice ? 2 * singlePrice - couplesPrice : null

  /**
   * [1] `auto` used to push surrounding content to either the top or bottom of
   *      the card (depending on if we're showing a text description or
   *      bulleted list).
   */
  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Grid.Item className={className} {...gridItemProps}>
        <StyledWrapper background={COLOR.WHITE}>
          <StyledTopWrapper>
            <StyledHeader
              background={COLOR.ACCENT.PRIMARY_20}
              padding={['S', 'M']}
            >
              <StyledTitle size="S" tag="h3">
                {title}
              </StyledTitle>
            </StyledHeader>
            <StyledContentWrapper padding={['S', 'M', 'M']}>
              {description && (
                <P margin={[0, 0, 'auto' /* [1] */]} padding={[0, 0, GTR.S]}>
                  {description}
                </P>
              )}
              {isFree ? (
                <>
                  <H size="M" tag="h4" margin={0}>
                    <StyledFreePrice>{`£${singlePrice}`}</StyledFreePrice>{' '}
                    <StyledFree aria-label="Free with your discount code">
                      Free
                    </StyledFree>
                  </H>
                  <P color={PRICE_DISCOUNT_RED} aria-hidden>
                    With your discount code
                  </P>
                </>
              ) : (
                <>
                  <H size="M" tag="h4" margin={0}>
                    {`£${singlePrice}`}
                  </H>
                  <P color={COLOR.BLACK}>
                    £{couplesPrice} for couples{' '}
                    {COUPLES_SAVING && ` • Save £${COUPLES_SAVING}`}
                  </P>
                </>
              )}
              {bullets && (
                <StyledBulletList
                  tag="ul"
                  margin={[0, 0, 'auto' /* [1] */]}
                  padding={[0, 0, GTR.S]}
                >
                  {bullets.map(({ icon, text }) => (
                    <li key={text}>
                      <StyledBulletIconWrapper>{icon}</StyledBulletIconWrapper>
                      {text}
                    </li>
                  ))}
                </StyledBulletList>
              )}
              <Cta
                isFree={isFree}
                telephoneWillsEnabled={telephoneWillsEnabled}
              />
            </StyledContentWrapper>
          </StyledTopWrapper>

          {(footerText || optionalExtras) && (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Wrapper>
              <Divider />
              {footerText && (
                <P size="S" padding={['S', 'M']}>
                  {footerText}
                </P>
              )}

              {optionalExtras && (
                <OptionalExtras
                  alwaysShowOptionalExtras={alwaysShowOptionalExtras}
                  optionalExtras={optionalExtras}
                />
              )}
            </Wrapper>
          )}
        </StyledWrapper>
      </Grid.Item>
    </>
  )
}

export default WillOptionCard
