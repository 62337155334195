import React from 'react'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'
import WrapperWithEdge from 'components/WrapperWithEdge'

import type { WillOptionsProps } from '../index'

type WillOptionsWrapperProps = {
  children: React.ReactNode
  className?: string
  edgeBackgroundColor: WillOptionsProps['edgeBackgroundColor']
  edgeDirection: WillOptionsProps['edgeDirection']
}

const WillOptionsWrapper = ({
  edgeBackgroundColor,
  edgeDirection,
  children,
  className,
}: WillOptionsWrapperProps) => {
  if (edgeDirection === 'none' || edgeDirection === undefined) {
    return (
      <Wrapper background={COLOR.ACCENT.PRIMARY} className={className}>
        {children}
      </Wrapper>
    )
  }

  return (
    <WrapperWithEdge
      className={className}
      direction={edgeDirection}
      edgeBackgroundColor={edgeBackgroundColor}
    >
      {children}
    </WrapperWithEdge>
  )
}

const StyledWrapper = styled(WillOptionsWrapper)`
  overflow: hidden;
  position: relative;
`

export default StyledWrapper
