import React from 'react'
import styled from 'styled-components'
import { Wrapper, P } from '@farewill/ui'
import { FONT, GTR } from '@farewill/ui/tokens'

import Dropdown, { StyledDropdownPrompt } from 'components/Dropdown'
import type { OptionalExtra } from 'views/wills/components/WillOptions/types'

export type OptionalExtrasProps = {
  alwaysShowOptionalExtras?: boolean
  optionalExtras: OptionalExtra[]
}

const StyledDropdownWrapper = styled(Wrapper)`
  ${StyledDropdownPrompt} {
    font-size: ${FONT.SIZE.S};
    padding: ${GTR.S} ${GTR.M};
  }
`

const StyledOptionalExtra = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
`

const OptionalExtras = ({
  alwaysShowOptionalExtras = false,
  optionalExtras,
}: OptionalExtrasProps): React.ReactElement => {
  const content = optionalExtras.map(({ name, cost }) => (
    <StyledOptionalExtra key={name} margin={[0, 0, 'XS']}>
      <P tag="dt" size="S" margin={0}>
        {name}
      </P>
      <P tag="dd" size="S" margin={0}>
        {cost}
      </P>
    </StyledOptionalExtra>
  ))

  if (alwaysShowOptionalExtras) {
    return (
      <Wrapper margin={0} padding="M" tag="dl">
        {content}
      </Wrapper>
    )
  }

  return (
    <StyledDropdownWrapper>
      <Dropdown title="Show optional extras" openTitle="Hide optional extras">
        <Wrapper margin={0} padding={['XXS', 'M', 'M']} tag="dl">
          {content}
        </Wrapper>
      </Dropdown>
    </StyledDropdownWrapper>
  )
}

export default OptionalExtras
